.b-find_near_me{
    display: flex;
    margin-top: var(--marginTop);
    justify-content: var(--horiz-align);
    &--error{
        &.b-text_box{
            display: none;
            padding:  var(--l) var(--s);
        }

    }
    .b-store_locator{
        width: var(--component-width, 100%);
        margin:0;
         padding:0;
    }

	.f-input_text-field{
		&:hover{
			border-color: inherit;
		}
	}
    .b-store_locator-wrapper {
        display: flex;
        margin-bottom: 0;
    }
	.wtb-search-button{
		flex-shrink:0;
	}
    .b-store_locator-form {
        width: 100%;
        display: flex;
        gap: 1.5rem;
        align-items: center;
    }

    .wtb-zip-form .form-group{
        width: 100%;
    }
    .b-store_locator-submit_wrapper{
        margin-top: 0;
    }
    .b-store_locator-submit_wrapper #submit {
        display: none;
        &[class*="link"]{
            appearance: none;
            background-color: transparent;
            border: none;
        }
        &.show{
            display: inline;
        }

    }


}
    .pd-store-locator .b-store_locator-content,
    .pd-store-locator .b-store_locator-wrapper:before,
    .pd-store-locator .b-store_locator-title,
    .pd-store-locator .b-store_locator-description {
        display: none;
    }

    .pd-store-locator * {
        pointer-events: none;
        width: 0 !important;
        height: 0 !important;
        margin: 0 !important;
        opacity: 0 !important;
        padding: 0 !important;
        gap: 0 !important;
    }